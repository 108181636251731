import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { close_notification } from '../../store/slices/notificationSlice'

const Notification = () => {
    const {is_open , notification , notificationType} = useSelector((state) => state.notification)
    const dispatch = useDispatch()
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        dispatch(close_notification());
      };

  return (
    <Snackbar open={is_open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={notificationType} sx={{ width: '100%' , bgcolor : 'grey.300' }}>
          {notification}
        </Alert>
      </Snackbar>
  )
}

export default Notification