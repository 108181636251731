import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    branch_id : 1
}

const branchSlice = createSlice({
    name : 'branch',
    initialState,
    reducers : {
        change_branch : (state , action ) => {
            state.branch_id = action.payload
        }
    }
})


export default branchSlice

export const { change_branch } = branchSlice.actions