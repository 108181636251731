import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notification : '',
    notificationType : 'success',
    is_open : false
}

const notificationSlice = createSlice({
    name : 'notification',
    initialState,
    reducers : {
        new_notification : (state , action) => {
            state.notification = action.payload.message
            state.notificationType = action.payload.messageType
            state.is_open = true
        },
        close_notification : (state) => {
            state.is_open = false
        }
    }
})


export default notificationSlice

export const { new_notification , close_notification } = notificationSlice.actions