import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ingredients : [],
    ingredientaForSubmiting : []
}

const ingredients = createSlice({
    name : 'ingredients',
    initialState,
    reducers : {
        add_new_ingredient : (state) => {
            state.ingredients.push({
                key : state.ingredients.length,
                id : 0,
                quantity : 0,
                consuming_unit : 'kg'
            })
        },
        delete_ingredient : (state , action) => {
            state.ingredients = state.ingredients.filter((obj) => obj.key !== action.payload)
        },
        update_ingredient : (state , action) => {
            let oldArray = [...state.ingredients]
            oldArray.forEach((element , i) => {
                if(element.key === action.payload.key){
                    const ingredient = {
                        key : element.key,
                        id : action.payload.id,
                        quantity : action.payload.quantity,
                        consuming_unit : action.payload.consuming_unit
                    }
                    oldArray[i] = ingredient
                }
            });
            state.ingredients = oldArray
        },
        ready_to_submiting : (state) => {
            let data = state.ingredients.map((ingredient) => {
                return {
                    id : ingredient.id,
                    quantity : ingredient.quantity,
                    consuming_unit : ingredient.consuming_unit
                }
            })

            state.ingredientaForSubmiting = data
        },
        reset : (state) => {
            state.ingredientaForSubmiting = []
            state.ingredients = [] 
        }
    }
})


export default ingredients

export const {add_new_ingredient , delete_ingredient , update_ingredient , ready_to_submiting , reset} = ingredients.actions