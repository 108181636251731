import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";

const initialState = {
    stateOptions : ['day', 'month' , 'year' , 'between'],
    state : 'day',
}

const filterSlice = createSlice({
    name : 'filter',
    initialState,
    reducers : {
        change_filter_type : (state , action) => {
            state.state = action.payload
        },
    }
})


export default filterSlice

export const {change_filter_type} = filterSlice.actions