import { lazy } from "react"
import Loadable from "../components/Loadable"

const Dashboard = Loadable(lazy(() => import('../view/dashboard')))
const MainLayout = Loadable(lazy(() => import('../layouts/MainLayout')))
const Products = Loadable(lazy(() => import('../view/pages/Products')))
const Categories = Loadable(lazy(() => import('../view/pages/Categories')))
const Offers = Loadable(lazy(() => import('../view/pages/Offers')))
const PublicOffers = Loadable(lazy(() => import('../view/pages/PublicOffers')))
const SupCategories = Loadable(lazy(() => import('../view/pages/SupCategory')))
const Orders = Loadable(lazy(() => import('../view/pages/Orders')))
const Suppliers = Loadable(lazy(() => import('../view/pages/Suppliers')))
const IngredientSupplier = Loadable(lazy(() => import('../view/pages/IngredientSupplier')))

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Dashboard />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <Dashboard />
        }
      ]
    },
    {
      path: 'pages',
      children: [
        {
          path: 'products',
          element: <Products />
        }
      ]
    },
    {
      path: 'pages',
      children: [
        {
          path: 'categories',
          element: <Categories />
        }
      ]
    },
    {
      path: 'pages',
      children: [
        {
          path: 'SubCategories',
          element: <SupCategories />
        }
      ]
    },
    // {
    //   path: 'pages',
    //   children: [
    //     {
    //       path: 'ingrediants',
    //       element: <Ingredients />
    //     }
    //   ]
    // },
    {
      path: 'pages',
      children: [
        {
          path: 'offers',
          element: <Offers />
        }
      ]
    },
    {
      path: 'pages',
      children: [
        {
          path: 'publicOffers',
          element: <PublicOffers />
        }
      ]
    },
    // {
    //   path: 'pages',
    //   children: [
    //     {
    //       path: 'orders',
    //       element: <Orders />
    //     }
    //   ]
    // // },
    // {
    //   path: 'pages',
    //   children: [
    //     {
    //       path: 'suppliers',
    //       element: <Suppliers />
    //     }
    //   ]
    // },
    // {
    //   path: 'pages',
    //   children: [
    //     {
    //       path: 'incoming',
    //       element: <IngredientSupplier />
    //     }
    //   ]
    // },
  ]
};

  export default MainRoutes
