import { lazy } from "react";
import Loadable from "../components/Loadable";

const MinimalLayout = Loadable(lazy(() => import('../layouts/MinimalLayout')))
const AuthLogin3 = Loadable(lazy(() => import('../view/pages/authentication/authentication3/Login3.js')))

const AuthenticationRoutes = {
    path: '/',
    element: <AuthLogin3 />,
    children: [
      {
        path: '/pages',
        element: <MinimalLayout />
      }
    ]
  };
export default AuthenticationRoutes 