import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import customization from "./slices/customization";
import authorizationSlice from "./slices/authorizationSlice";
import notificationSlice from "./slices/notificationSlice";
import branchSlice from "./slices/branchSlice";
import ingredients from "./slices/ingredientSlice";
import serverSideNotification from "./slices/serverSideNotification";
import { createLogger } from "redux-logger";
import filterSlice from "./slices/filterSlice";

const logger = createLogger();

const store = configureStore({
    reducer : {
        customization : customization.reducer,
        authorization : authorizationSlice.reducer,
        notification : notificationSlice.reducer,
        branch : branchSlice.reducer,
        ingredients : ingredients.reducer,
        serverSideNotification : serverSideNotification.reducer,
        filter : filterSlice.reducer
    },

    middleware : (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store