import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
    is_auth : false,
    token : '',
    profile : ''
}

const authorizationSlice = createSlice({
    name : 'authorization',
    initialState,
    reducers : {
        login : (state , action) => {
            state.is_auth = true
            state.token = action.payload.token
            state.profile = action.payload.profile
            Cookies.set('is_godiva_user_auth' , true)
            Cookies.set('godiva_token_4536' , action.payload.token)
            Cookies.set('godiva_user_profile_4536' , action.payload.profile)
        },
        logout : (state) => {
            state.is_auth = false
            state.token = ''
            state.profile = ''
            Cookies.remove('is_godiva_user_auth')
            Cookies.remove('godiva_token_4536')
            Cookies.remove('godiva_user_profile_4536')
        }
    }
})

export default authorizationSlice

export const { login , logout } = authorizationSlice.actions