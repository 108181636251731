import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import NavigationScroll from './layouts/NavigationScroll'
import Routes from './routes'
import themes from './themes';
import { useSelector } from 'react-redux';
import Notification from './layouts/Notification';

const App = () => {
  const customization = useSelector((state) => state.customization)
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
        <Notification />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App