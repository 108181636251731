import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../../../api/request";
import store from "../..";



const initialState = {
    notification : [],
    isLoading : false,
    error : ''
}

const fetchNotification = createAsyncThunk('serverSideNotification/fetchNotification' , () => {
    return request({
        url : `/notifications/${store.getState().branch.branch_id}`
    }).then(response => response.data.data)
})

const serverSideNotification = createSlice({
    name : 'serverSideNotification',
    initialState,
    reducers : {
        add_new_notification : (state , action) => {
            state.notification.push(action.payload)
        },
        mark_all_as_read : (state) => {
            state.notification = []
        }
    },
    extraReducers : (builder) => {
        builder.addCase(fetchNotification.pending , (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchNotification.fulfilled , (state , action) => {
            state.isLoading = false
            state.notification = action.payload
            state.error = ''
        })
        builder.addCase(fetchNotification.rejected , (state , action) => {
            state.isLoading = false
            state.notification = []
            state.error = action.error.message
        })
    }
})

export const { add_new_notification  , mark_all_as_read } = serverSideNotification.actions

export {fetchNotification}

export default serverSideNotification
