import { createSlice } from "@reduxjs/toolkit";
import config from "../../../config";

const initialState = {
    isOpen: [], // for active default menu
    defaultId: 'default',
    opened: true,
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
}

const customization = createSlice({
    name : 'customization',
    initialState,
    reducers : {
        MENU_OPEN : (state , action) => {
            state.isOpen = [action.payload]
        },
        SET_MENU : (state , action) => {
            state.opened = action.payload
        },

    }
})


export default customization

export const { MENU_OPEN , SET_MENU } = customization.actions